// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict'

    var $window = $(window),
        $oHeader = $('#header');


    $('#searchCollapse').on('shown.bs.collapse', function () {
        document.getElementById('searchParam').focus();
        $('body').addClass(['fixed', 'search-open']);
        $('.navbar-collapse').removeClass('show');
        //$('.backdrop').fadeIn('fast');
    }).on('hide.bs.collapse', function () {
        $('body').removeClass('fixed')
        //$('.backdrop').fadeOut('fast');
    })

    $('body').on('close-search', function () {
        $('#searchCollapse').removeClass('show');
        $('.navbar-collapse').removeClass('show')
        $('body').removeClass(['fixed', 'search-open'])
        $('.backdrop').fadeOut('fast');
    })


    $('.navbar-collapse').on('shown.bs.collapse', function () {
        $('body').addClass('fixed');
        $('#searchCollapse').removeClass('show');
        $('.backdrop').fadeIn('fast');
    }).on('hide.bs.collapse', function () {
        $('body').removeClass('fixed')
        $('.backdrop').fadeOut('fast');
    })

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        })

    $oHeader.find('.menu-dropdowns button[data-href]').click(function (e) {
            var $this = $(this);

            if ($window.width() <= 990) {
                e.stopPropagation();
                document.location.href = $this.attr('data-href');
            }
        }
    );

})()

//use window.scrollY
var scrollpos = window.scrollY;
var header = document.querySelector(".header");

function add_class_on_scroll() {
    header.classList.add("collapse-elements");
}

function remove_class_on_scroll() {
    header.classList.remove("collapse-elements");
}

$(".accordeon-text-inner p").filter(function () {
    var $contents = $(this).contents();
    return $contents.eq(0).is("a");
}).replaceWith(function () {
    var $newContents = "<p><span class='btn-me'>" + $(this).html() + "</span></p>";
    $('.btn-me a').addClass('btn btn-outline-primary');
    return $newContents
});

function desktopOnly() {
    if (window.innerWidth > 992) {
        window.addEventListener('scroll', function () {
            //Here you forgot to update the value
            scrollpos = window.scrollY;

            if (scrollpos > 500) {
                add_class_on_scroll();
                $('.fadeoutsticky').fadeOut('fast')
            } else if (scrollpos < 30) {
                remove_class_on_scroll();
                $('.fadeoutsticky').fadeIn('fast')
            }
        })

        document.querySelectorAll('.navbar .nav-item, .categorytree .nav-item').forEach(function (everyitem) {

            everyitem.addEventListener('mouseover', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');
                }

            });

            everyitem.addEventListener('mouseleave', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]');

                if (el_link != null) {
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }
            })

            everyitem.addEventListener('click', function (e) {
                let el_link = this.querySelector('a[data-bs-toggle]').getAttribute('data-href');
                if (el_link != null) {
                    window.location.replace(el_link);
                }
            })
        });
    }
}



desktopOnly();
$(window).resize(function () {
    desktopOnly();
});

$(document).ready(function () {
    $('.btn-number').click(function (e) {
        e.preventDefault();
        fieldName = $(this).attr('data-field');
        type = $(this).attr('data-type');
        var input = $("input[name='" + fieldName + "']");
        var currentVal = parseInt(input.val());

        if (!isNaN(currentVal)) {
            if (type == 'minus') {
                if (currentVal > input.attr('min')) {
                    input.val(currentVal - 1).change();
                }
            } else if (type == 'plus') {
                if (currentVal < input.attr('max')) {
                    input.val(currentVal + 1).change();
                }
            }
        } else {
            input.val(0);
        }
    });
});